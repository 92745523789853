


























import {PlainObject} from '@labor-digital/helferlein';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import CmsTextLinkBlock from '../../../Component/Misc/Link/CmsTextLinkBlock/CmsTextLinkBlock.vue';

export default {
    name: 'TextContentElement',
    components: {CmsTextLinkBlock},
    props: {
        context: null as ContentElementContext
    },
    data()
    {
        return {
            listStyle: this.context.data.get('listType', '')
        };
    },
    computed: {
        bodyText(): string
        {
            return this.context.data.get('bodyText', '');
        },
        listClasses(): PlainObject
        {
            return {
                'textContentElement--default': this.listStyle === 'default',
                'textContentElement--check': this.listStyle === 'check',
                'textContentElement--arrow': this.listStyle === 'arrow',
                'textContentElement--x': this.listStyle === 'x'
            };
        }
    }
};
