var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("cms-text-link-block", [
        _c("div", {
          staticClass: "textContentElement",
          class: _vm.listClasses,
          domProps: { innerHTML: _vm._s(_vm.bodyText) },
        }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }